<template>
  <div class="proposal-form">
    <div class="form-group required">
      <ComponentInput
        :select2Settings="select2Settings"
        :select2Options="industries"
        v-model="answerData.industryId"
        required
        label="Отрасль"
        type="select2"
      />
    </div>
    <div class="form-group required">
      <ComponentInput
        v-model="answerData.body"
        type="textarea"
        label="Предложение"
        required
        placeholder="Введите текст предложения"
      />
    </div>
    <div class="btns mb-16">
      <ButtonStock btnType="secondary" title="Отмена" v-on:click="close" />
      <ButtonStock title="Сохранить" v-on:click="save" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import Constants from '@/common/constants';

  import ComponentInput from '../../../common/components/ComponentInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import NsiApi from '../../nsi/api/nsi';

  export default {
    name: 'ProposalForm',
    components: { ButtonStock, ComponentInput },
    props: {
      answer: Object,
    },
    emits: ['save', 'close'],
    data() {
      return {
        industries: [],
        answerData: this.answer ?? {},
        select2Settings: Constants.select2Settings,
      };
    },
    created() {
      NsiApi.search('nsiindustry', { pageNumber: 1, pageSize: 0, isActive: true })
        .then((response) => {
          this.industries = response.data.items.map((x) => ({ id: x.id, text: x.name }));
        })
        .catch((error) => {
          console.error(error?.response?.data);
        });
    },
    methods: {
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire(Constants.errorTitle, Constants.validationError, 'error');
          return;
        }
        this.$emit('save', this.answerData);
      },
      close() {
        this.$emit('close');
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        answerData: {},
      };
    },
    watch: {
      answer: {
        handler: function () {
          this.answerData = this.answer ?? { industryId: null, body: '' };
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .proposal-form {
    .btns {
      display: flex;
      justify-content: flex-end;
      button {
        &:not(:last-of-type) {
          margin-right: 16px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .proposal-form {
      .btns {
        flex-wrap: wrap;
        justify-content: flex-start;
        button {
          width: 100%;
          &:not(:last-of-type) {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
</style>
