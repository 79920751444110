<template>
  <div class="container-page">
    <tabs>
      <tab name="Информация запроса" :selected="true">
        <div class="make-request-info__btns btns-container">
          <ButtonStock title="Направить уведомление" />
        </div>
        <div v-if="Object.keys(card).length" class="make-request-info mb-16">
          <div class="make-request-info__card card-info">
            <div class="card-info__row">
              <div class="card-info__row-col">
                <p class="title">Статус запроса</p>
                <p class="request-status" :class="card.status.cssClass">{{ card.status.name }}</p>
              </div>
              <div class="card-info__row-col">
                <p class="title">Получатель</p>
                <p>{{ card.recipientOrg?.name || card.recipientDept?.name || card.email }}</p>
              </div>
            </div>
            <div class="card-info__row">
              <div class="card-info__row-col">
                <p class="title">Дата отправки</p>
                <p>{{ $momentFormat(card.created, 'DD MMMM YYYY HH:mm') }}</p>
              </div>
              <div class="card-info__row-col">
                <p class="title">Плановая дата ответа</p>
                <p>{{ $momentFormat(card.answerDeadline, 'DD MMMM YYYY') }}</p>
              </div>
            </div>
            <div class="card-info__row">
              <div class="card-info__row-col">
                <p class="title">Текст запроса</p>
                <p>{{ card.body }}</p>
              </div>
              <div class="card-info__row-col">
                <p class="title">Запрошенные предложения</p>
                <p>{{ card.proposals.map((x) => x.requestType.name).join('; ') }}</p>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab name="Ответ">
        <div v-if="canSendToApproval" class="form-group">
          <ButtonStock title="Отправить на согласование" @click="sendToApproval" />
        </div>
        <div v-if="card.proposals?.length" class="make-request-answer__cards mb-16">
          <div v-for="proposal in card.proposals" :key="proposal.id" class="make-request-answer__cards-item">
            <p class="label">
              <span>{{ proposal.requestType.name }}</span>
              <span class="textDecorUnderLine" @click="selectAll('answerList_' + proposal.id)">Выбрать все</span>
            </p>
            <div v-if="canEditAnswer" class="make-request-answer__cards-item-btns">
              <div class="edit">
                <ButtonStock class="w-100" title="Добавить" @click="addBlockItem(proposal)" />
              </div>
            </div>
            <div class="make-request-answer__cards-item-multiselect is-visible custom-inMultiselect">
              <Multiselect
                :native="true"
                placeholder="Выберите"
                mode="multiple"
                :showOptions="true"
                :hideSelected="false"
                :close-on-select="false"
                :searchable="true"
                :groups="proposal.requestTypeId > 2"
                :create-option="false"
                :multiple-label="(values) => `${values.length} выбрано`"
                :options="makeProposalOptions(proposal)"
                :ref="`answerList_${proposal.id}`"
                v-model="proposal.selectedAnswerIds"
                noOptionsText="Список пуст."
                noResultsText="Ничего не найдено."
              >
                <template v-slot:option="{ option }">
                  <div class="multiselect-checkBox gradient">
                    <div class="multiselect-checkBox__arrow"></div>
                  </div>
                  <p class="multiselect-text">
                    {{ option.label }}
                    <span v-if="option.extraInfo"><br />{{ option.extraInfo }}</span>
                  </p>
                  <p
                    v-if="!!option.status && option.status.id > 2"
                    class="request-status"
                    :class="option.status.cssClass"
                  >
                    {{ option.status.title }}
                  </p>
                </template>
              </Multiselect>
            </div>
            <div class="make-request-answer__cards-item-btns">
              <div v-if="canEditAnswer" class="w100">
                <ButtonStock
                  btnType="gray"
                  mode="icon"
                  iconName="mode"
                  title="Редактировать"
                  class="w100 justify-content-center"
                  @click="editBlockItem(proposal)"
                />
              </div>
              <ButtonStock
                v-if="canChangeAnswerStatus"
                title="Согласовать"
                @click="changeStatus(proposal, moderationtStatus.accepted)"
              />
              <ButtonStock
                v-if="canChangeAnswerStatus"
                btnType="white"
                title="Отклонить"
                @click="changeStatus(proposal, moderationtStatus.rejected)"
              />
            </div>
          </div>
        </div>
      </tab>
    </tabs>
    <div class="d-flex justify-content-end mt-2">
      <ButtonStock btn-type="secondary" title="Закрыть" @click="close" />
    </div>
  </div>
  <section>
    <ModalComponent v-model="modals.proposalForm" title="Карточка предложения" @close="closeModal">
      <ProposalForm :answer="modals.answerData" @save="saveProposalForm" @close="closeModal" />
    </ModalComponent>
    <ModalComponent v-model="modals.participantForm" title="Выбор участника" @close="closeModal">
      <CommissionParticipantBlock
        v-bind:value="modals.answerData"
        :country-id="modals.answerData.countryId"
        :country-name="modals.answerData.countryName"
        :participant-category-id="modals.answerData.participantCategoryId"
        :participant-category-name="modals.answerData.participantCategoryName"
        @save="saveParticipantForm"
        @close="closeModal"
      />
    </ModalComponent>
  </section>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import API from '../api/eventrequest';
  import ParticipantApi from '../api/participant';
  import CommissionParticipantBlock from '../components/CommissionParticipantChoose';
  import ProposalForm from '../components/ProposalForm';

  export default {
    name: 'EventRequestView',
    components: {
      CommissionParticipantBlock,
      ProposalForm,
      ModalComponent,
      ButtonStock,
      tabs,
      tab,
      Multiselect,
    },
    mixins: [security],
    data() {
      return {
        id: this.$route.params.id,
        card: {},
        modals: {
          proposalForm: false,
          participantForm: false,
          requestProposalId: null,
          answerData: {},
        },
        moderationtStatus: Constants.moderationtStatus,
        defaultParticipantCategoryId: 4,
        defaultParticipantCategoryName: '',
        canSendToApproval: false,
        canEditAnswer: false,
        canChangeAnswerStatus: false,
      };
    },
    created() {
      this.loadCard();
      Utils.searchNsi('mpkparticipantcategorylist', { isActive: true })
        .then((response) => {
          this.defaultParticipantCategoryName = response.data.find(
            (x) => x.id === this.defaultParticipantCategoryId.toString(),
          ).text;
        })
        .catch((error) => {
          console.error(error?.response?.data);
        });
    },
    methods: {
      loadCard() {
        API.view(this.id)
          .then((response) => {
            this.card = response.data;
            this.applyPermissions();
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      applyPermissions() {
        this.checkPermission(Constants.Permissions.MpkEventRequestEdit).then((response) => {
          this.canEditAnswer = response.data && this.card.statusId <= Constants.eventRequestStatus.answerExpected;
        });
        this.checkPermission(Constants.Permissions.MpkEventRequestChangeStatus).then((response) => {
          this.canSendToApproval = response.data && this.card.statusId === Constants.eventRequestStatus.answerExpected;
        });
        this.checkPermission(Constants.Permissions.MpkEventRequestAnswerProposalChangeStatus).then((response) => {
          this.canChangeAnswerStatus =
            response.data && this.card.statusId === Constants.eventRequestStatus.answerReceived;
        });
      },
      makeProposalOptions(proposal) {
        let result = [];
        if (proposal.requestTypeId > 2) {
          let grouping = Utils.groupList(
            proposal.answers,
            (x) => ({ id: x.industryId, text: x.industry.name }),
            (x) => ({ id: x.id, text: x.body, status: x.status }),
          );
          grouping.forEach((g) => {
            let item = {
              label: g.text,
              options: [],
            };
            g.children.forEach((c) => {
              item.options.push({
                value: c.id,
                label: c.text,
                status: c.status,
              });
            });
            result.push(item);
          });
        } else {
          result = proposal.answers.map((x) => ({
            value: x.id,
            label: x.participant.name,
            img: x.participant.picture,
            status: x.status,
            extraInfo: x.participant.position,
          }));
        }
        return result;
      },
      selectAll(key) {
        this.$refs[key].clear();
        this.$refs[key].selectAll();
      },
      addBlockItem(proposal) {
        this.modals.answerData = {
          eventRequestProposalId: proposal.id,
          statusId: Constants.moderationtStatus.new,
        };
        switch (proposal.requestTypeId) {
          case Constants.proposalRequestType.participantsNarrowCircle:
          case Constants.proposalRequestType.participantsPlenarySession:
            this.modals.answerData.countryId = this.card.event.countryId;
            this.modals.answerData.countryName = this.card.event.country?.name;
            this.modals.answerData.participantCategoryId = this.defaultParticipantCategoryId;
            this.modals.answerData.participantCategoryName = this.defaultParticipantCategoryName;
            this.modals.participantForm = true;
            break;
          default:
            this.modals.answerData = Object.assign(this.modals.answerData, { industryId: null, body: '' });
            this.modals.proposalForm = true;
            break;
        }
      },
      editBlockItem(proposal) {
        if (!proposal.selectedAnswerIds?.length) {
          Constants.alert.fire('Редактирование', 'Отметьте в списке элемент, который вы хотите изменить.', 'warning');
          return;
        }

        const id = proposal.selectedAnswerIds[0];
        const answerData = proposal.answers.find((x) => x.id === id);

        switch (proposal.requestTypeId) {
          case Constants.proposalRequestType.participantsNarrowCircle:
          case Constants.proposalRequestType.participantsPlenarySession:
            this.modals.answerData = {
              eventRequestProposalId: answerData.eventRequestProposalId,
              answerId: answerData.id,
              id: answerData.participantId,
              countryId: this.card.event.countryId,
              countryName: this.card.event.country?.name,
              participantCategoryId: this.defaultParticipantCategoryId,
              participantCategoryName: this.defaultParticipantCategoryName,
            };
            this.modals.participantForm = true;
            break;

          default:
            this.modals.answerData = answerData;
            this.modals.proposalForm = true;
            break;
        }
      },
      saveProposalForm(data) {
        API.saveAnswer(data)
          .then(() => {
            this.closeModal();
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch((error) => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            console.error(error?.response?.data);
          });
      },
      saveParticipantForm(data) {
        const answer = this.modals.answerData;
        if (data.id > 0) {
          const answerData = {
            id: answer.answerId || 0,
            eventRequestProposalId: answer.eventRequestProposalId,
            participantId: data.id,
            statusId: answer.statusId ?? Constants.moderationtStatus.new,
          };
          this.saveProposalForm(answerData);
        } else {
          ParticipantApi.update(data)
            .then((response) => {
              const answerData = {
                id: answer.answerId || 0,
                eventRequestProposalId: answer.eventRequestProposalId,
                participantId: response.data.id,
                statusId: answer.statusId ?? Constants.moderationtStatus.new,
              };
              this.saveProposalForm(answerData);
            })
            .catch((error) => {
              Constants.alert.fire('Добавление участника', Constants.commonError, 'error');
              console.error(error?.response?.data);
            });
        }
      },
      closeModal() {
        this.modals.participantForm = false;
        this.modals.proposalForm = false;
      },
      sendToApproval() {
        API.changeStatus(this.card.id, {
          statusId: Constants.eventRequestStatus.answerReceived,
        })
          .then(() => {
            Constants.alert.fire('Изменение статуса', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch((error) => {
            Constants.alert.fire('Изменение статуса', Constants.commonError, 'error');
            console.error(error?.response?.data);
          });
      },
      changeStatus(proposal, statusId) {
        if (!proposal.selectedAnswerIds?.length) {
          Constants.alert.fire('Изменение статуса', 'Отметьте элементы в списке.', 'warning');
          return;
        }
        const action = statusId === Constants.moderationtStatus.accepted ? 'Согласовать' : 'Отклонить';
        let options = Constants.confirmOptions.changeStatus;
        options.text = `${action} отмеченные предложения (${proposal.selectedAnswerIds.length})?`;
        Constants.alert.fire(Constants.confirmOptions.changeStatus).then((res) => {
          if (res.isConfirmed) {
            this.internalChangeStatus(proposal, statusId);
          }
        });
      },
      internalChangeStatus(proposal, statusId, comment) {
        API.bulkChangeAnswerStatus(proposal.id, {
          statusId: statusId,
          comment: comment,
          ids: proposal.selectedAnswerIds,
        })
          .then(() => {
            Constants.alert.fire('Изменение статуса', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch((error) => {
            Constants.alert.fire('Изменение статуса', Constants.commonError, 'error');
            console.error(error?.response?.data);
          });
      },
      close() {
        this.$router.push({ name: 'EventRequests' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btns-container {
    display: flex;
    button,
    a {
      margin-right: 18px;
      &:last-of-type {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
  .make-request-info {
    &__btns {
      margin-bottom: 28px;
    }
    &__btns,
    &__card {
      max-width: 941px;
    }
  }
  .card-info {
    background: #ffffff;
    box-shadow: 0 0 17px rgba(55, 55, 55, 0.08);
    border-radius: 5px;
    padding: 30px;
    width: 100%;
    &__row {
      display: flex;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px dashed #d7d7d7;
      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
      &-col {
        width: 50%;
        .title {
          font-weight: 500;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .card-info[data-v-2ee79afe] {
      padding: 12px;
    }
    .make-request-info__cards-item {
      max-width: unset;
    }
    .btns-container {
      flex-wrap: wrap;
    }
    .btns-container button,
    .btns-container a {
      max-width: unset;
      width: 100%;
      margin-bottom: 16px;
      margin-right: 0;
    }
  }
  @media (max-width: 767px) {
    .card-info__row {
      flex-wrap: wrap;
    }
    .card-info__row-col {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .make-request-answer {
    &__btns {
      margin-bottom: 72px;
    }
    &__cards {
      display: flex;
      flex-wrap: wrap;
      &-item {
        margin-right: 22px;
        max-width: 278px;
        width: 100%;
        &:last-of-type {
          margin-right: 0;
        }
        p {
          &.label {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 14px;
            span {
              &:last-of-type {
                cursor: pointer;
              }
            }
          }
        }
        &-multiselect {
          margin-bottom: 14px;
        }
        &-btns {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .edit {
            position: relative;
            width: 100%;
            margin-bottom: 14px;
            .icon {
              position: absolute;
              top: 10px;
              left: 28%;
            }
          }
          button {
            &.btn-gray {
              width: 100%;
            }
            &.btn-primary,
            &.btn-white {
              width: 45%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1010px) {
    // .make-request-answer__cards{
    //   flex-wrap: wrap;
    // }
    .make-request-answer__cards-item {
      margin-bottom: 24px;
      // margin-right: 0;
      max-width: 338px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 768px) {
    .make-request-answer__cards-item {
      max-width: unset;
      margin-right: 0;
    }
    .btns-container {
      flex-wrap: wrap;
    }
    .btns-container button,
    .btns-container a {
      max-width: unset;
      width: 100%;
      margin-bottom: 16px;
      margin-right: 0;
    }
  }

  .request-status {
    &.yellow {
      color: #eae000;
    }
    &.green {
      color: #31bc00;
    }
    &.red {
      color: #eb5757;
    }
  }
</style>
