import axios from 'axios';

export default {
  find: function (id) {
    return axios.get(`/api/mpkeventrequest/find/${id}`);
  },
  view: function (id) {
    return axios.get(`/api/mpkeventrequest/view/${id}`);
  },
  changeStatus: function (id, request) {
    return axios.patch(`/api/mpkeventrequest/ChangeStatus/${id}`, request);
  },
  saveAnswer: function (request) {
    if (request.id > 0) {
      return axios.put(`/api/mpkeventrequest/updateanswer/${request.id}`, request);
    } else {
      return axios.post(`/api/mpkeventrequest/createanswer/`, request);
    }
  },
  bulkChangeAnswerStatus: function (id, request) {
    return axios.patch(`/api/mpkeventrequest/bulkchangeanswerstatus/${id}`, request);
  },
};
